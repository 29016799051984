import styled, { css } from "styled-components";
import theme from "./theme";
import { mediaQueries } from "../../utils/mediaQuery";

// Large text component (h1)
export const LargeText = styled.h1`
  color: ${theme.color.black};
  font-family: ${theme.font.fontFamilyMundial};
  font-size: ${theme.fontSize.lg};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;

  ${mediaQueries("xl")`
    font-size: ${theme.fontSize.lg};
  `}

  // Variant for "About Us" section
  ${(props) =>
    props.isAboutUs &&
    css`
      font-size: ${theme.fontSize.xxl};

      ${mediaQueries("xl")`
        font-size: ${theme.fontSize.xl};
      `}
    `};
`;

// Small text component (h2)
export const SmallText = styled.h2`
  color: ${theme.color.grey};
  font-family: ${theme.font.fontFamilyMundial};
  font-size: ${theme.fontSize.sm};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;

  ${mediaQueries("xl")`
    font-size: ${theme.fontSize.xs};
  `}

  // Variant for "About Us" section
  ${(props) =>
    props.isAboutUs &&
    css`
      font-size: ${theme.fontSize.md};

      ${mediaQueries("xl")`
        font-size: ${theme.fontSize.sm};
      `}
    `};
`;

// Button component
export const Button = styled.button`
  display: flex;
  padding: 12px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: ${theme.color.primary};
  border-radius: 1px;
  border: none;
  color: white;
  transition: 0.2s;

  // Styling for navigation buttons
  .nav-btn {
    color: white;
    text-align: center;
    font-family: ${theme.font.fontFamilyMundial};
    font-size: ${theme.fontSize.sm};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    border: none;
  }

  // Hover effect
  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }

  // Variant for outlined buttons
  ${(props) =>
    props.$isOutlined &&
    css`
      .nav-btn {
        color: ${theme.color.primary};
      }
      border: 2px solid ${theme.color.primary};
      background: none;
    `};

  // Variant for service buttons
  ${(props) =>
    props.$isServices &&
    css`
      display: flex;
      width: fit-content;
      padding: 9px 50px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: var(--border-color, ${theme.color.bordercolor});
      border: none;
      border-radius: 2px;
      width: 190px;

      color: ${theme.color.black};
      font-family: ${theme.font.fontFamilyMundial};
      font-size: ${theme.fontSize.sm};
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */

      // Variant for selected service buttons
      ${(props) =>
        props.$isSelected &&
        css`
          background: ${theme.color.primary};
          box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25),
            0px 1px 3px -1px rgba(0, 0, 0, 0.3);
          color: white;
        `}
    `};
`;
