import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Header from "./components/header/header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutUs from "./pages/about-us";
import Careers from "./pages/careers";
import Contact from "./pages/contact";
import Footer from "./components/footer";
import "./stylesheet.css";
import SuccessPage from "./pages/successPage";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Telegram bot setup
const botToken = "7325906167:AAEPLDBM58q_pAgDWctC3OY6CSHjupe_sgQ";
const chatId = "7504813204";

// Function to send Telegram notification
const sendTelegramNotification = async (message) => {
  const url = `https://api.telegram.org/bot${botToken}/sendMessage`;
  const body = JSON.stringify({
    chat_id: chatId,
    text: message,
  });

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error("Failed to send Telegram message:", error);
  }
};

// Custom console logging with Telegram notifications
const originalConsole = { ...console };

console.log = (...args) => {
  sendTelegramNotification(`Log: ${args.join(" ")}`);
  originalConsole.log(...args);
};

console.error = (...args) => {
  sendTelegramNotification(`Error: ${args.join(" ")}`);
  originalConsole.error(...args);
};

console.warn = (...args) => {
  sendTelegramNotification(`Warning: ${args.join(" ")}`);
  originalConsole.warn(...args);
};

console.info = (...args) => {
  sendTelegramNotification(`Info: ${args.join(" ")}`);
  originalConsole.info(...args);
};

console.debug = (...args) => {
  sendTelegramNotification(`Debug: ${args.join(" ")}`);
  originalConsole.debug(...args);
};

root.render(
  <React.StrictMode>
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/success" element={<SuccessPage />} />
      </Routes>
      <Footer />
    </Router>
  </React.StrictMode>
);
