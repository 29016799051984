import styled from "styled-components";
import theme from "../global/theme";
import { mediaQueries } from "../../utils/mediaQuery";

export const ServiceCardWrapper = styled.div`
  display: flex;
  padding: 14px 29px 21px 18px;
  flex-direction: column;
  align-items: flex-start;
  width: 390px;
  height: fit-content;

  ${mediaQueries("sm")`
    width: 350px;
`}

  border-radius: 2px;
  background: #fff;

  box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25),
    0px 1px 3px -1px rgba(0, 0, 0, 0.3);

  .service-name {
    color: #000;
    font-family: Mundial;
    font-size: ${theme.fontSize.md};
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.5px;
    margin-top: 20px;
  }

  .service-description {
    color: var(--grey, #626262);
    font-family: Mundial;
    font-size: ${theme.fontSize.sm};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
    margin-top: 11px;
    margin-bottom: 20px;
    height: auto;
  }

  .more-details {
    display: flex;
    align-items: center;
    gap: 8px;

    .text {
      color: var(--grey, #626262);
      font-family: Mundial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
      text-decoration: none;
    }
  }

  .icon {
    display: flex;
    width: 43px;
    height: 43px;
    border-radius: 50%;
    position: relative;
    justify-content: center;
    align-items: center;

    background: ${theme.color.primary};
  }
`;
