import styled, { css } from "styled-components";
import theme from "./styles/global/theme";
import { mediaQueries } from "./utils/mediaQuery";

// Main layout component
export const MainLayout = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1300px;
  min-height: 60vh;

  // Styling for text and buttons container
  .text-and-buttons {
    width: 100%;
    max-width: 510px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    min-width: 471px;

    ${mediaQueries("md")`
      text-align: center;
      align-items: center;
      min-width: 350px;
    `}

    // Styling for decorative dots image
    #dots-img {
      width: 132.828px;
      height: 129.154px;
      transform: rotate(33.391deg);
      position: absolute;
      z-index: -1;
      top: -51px;
      left: -77px;
    }
  }

  // Layout for about us section
  .about-us-layout {
    display: flex;
    justify-content: space-between;
    gap: 100px;
  }

  // Hide about us image on xl screens
  .about-us-img {
    ${mediaQueries("xl")`
      display: none;
    `}
  }
`;

// Hero section component
export const HeroSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100vw;
  column-gap: 5rem;
  row-gap: 15rem;
  justify-content: space-between;
  align-items: center;

  // Styling for buttons container
  .buttons {
    display: flex;
    justify-content: space-between;
    width: 325px;
    margin-top: 2rem;
  }

  // Styling for home image container
  .home-img {
    position: relative;

    // Main image styling
    #main-img {
      width: 567px;
      height: 378px;
      object-fit: cover;

      ${mediaQueries("xl")`
        width: 295px;
        height: 378px;
        object-fit: cover;
      `}

      ${mediaQueries("lg")`
        width: 200px;
        height: 378px;
        object-fit: cover;
      `}

      ${mediaQueries("md")`
        width: 100%;
        max-width: 450px;
        height: 275px;
      `}

      ${mediaQueries("sm")`
        max-width: 350px;
      `}
    }

    // Rings image styling
    #rings-img {
      position: absolute;
      bottom: -40px;
      right: -43px;

      ${mediaQueries("md")`
        display: none;
      `}
    }
  }

  ${mediaQueries("md")`
    row-gap: 5rem;
    justify-content: center;
  `}
`;

// Trusted section component
export const TrustedWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // Styling for "trusted by" text and lines
  .trusted-by {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    gap: 10px;

    span {
      color: ${theme.color.lightgrey};
      font-family: Mundial;
      font-size: ${theme.fontSize.sm};
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;

      ${mediaQueries("xl")`
        font-size: ${theme.fontSize.xs};
      `}
    }
  }

  // Styling for brand logos
  .brand-logos {
    display: flex;
    align-items: center;
    gap: 12rem;

    ${mediaQueries("xl")`
      img {
        width: 120px;
      }
    `}

    ${mediaQueries("md")`
      img {
        width: 100px;
      }
      gap: 75px;
    `}

    ${mediaQueries("sm")`
      img {
        width: 75px;
      }
      gap: 20px;
    `}
  }
`;

export const Line = styled.div`
  background: ${theme.color.lightgrey};
  height: 2px;
  width: 300px;

  ${mediaQueries("xl")`
        width: 200px;
      `}

  ${mediaQueries("md")`
        width: 125px;
      `}

      ${mediaQueries("sm")`
        width: 75px;
      `}
`;

// Our services section component
export const OurServicesWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 6rem;

  // Styling for service filter buttons
  .service-filter-btns {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    ${mediaQueries("md")`
      justify-content: center;
    `}
  }

  ${mediaQueries("md")`
    align-items: center;
  `}
`;

// Service grid component
export const ServiceGridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 25px;
  margin-top: 6rem;
`;

// Resources section component
export const ResourcesWrapper = styled.div`
  max-width: 988px;
  width: 100%;
  margin-top: 10rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${mediaQueries("md")`
    align-items: center;
    padding: 25px;
  `}

  // Background styling
  background-image: linear-gradient(
      92deg,
      rgba(20, 50, 82, 0.97) 30.71%,
      rgba(20, 50, 82, 0.37) 99.86%
    ),
    url("/images/resourcebg1.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  padding: 50px;
  color: #fff;
  gap: 20px;

  // Title styling
  .title {
    font-family: Mundial;
    font-size: ${theme.fontSize.lg};
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    ${mediaQueries("md")`
      font-size: ${theme.fontSize.lg};
      text-align: center;
    `}
  }

  // Description styling
  .description {
    max-width: 670px;
    font-family: Mundial;
    font-size: ${theme.fontSize.sm};
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    ${mediaQueries("md")`
      font-size: ${theme.fontSize.xs};    
      text-align: center;
      font-weight: 200;
    `}
  }

  // Button styling
  button {
    width: 220px;
    background: #fff;
    border: none;

    .resource-link {
      color: ${theme.color.primary};
      text-align: center;
      font-family: Mundial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
      cursor: pointer;
      text-decoration: none;
      display: flex;
      padding: 12px 40px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 1px;
      background: #fff;
      border: none;
    }
  }

  // Decorative image styling
  img {
    position: absolute;
    top: -85px;
    left: -89px;
    z-index: -1;

    ${mediaQueries("md")`
      display: none;
    `}
  }

  // Conditional styling for contact section
  ${(props) =>
    props.$isContact &&
    css`
      margin-top: 9.5rem;
      background-image: linear-gradient(
          92deg,
          rgba(20, 50, 82, 0.97) 30.71%,
          rgba(20, 50, 82, 0.37) 99.86%
        ),
        url("/images/resourcebg2.png");
    `};
`;
