import {
  HeroSection,
  Line,
  MainLayout,
  OurServicesWrapper,
  ResourcesWrapper,
  ServiceGridWrapper,
  TrustedWrapper,
} from "./index.style";

import { Button, LargeText, SmallText } from "./styles/global/main.style";
import services from "./data/services";
import ServiceCard from "./components/services/ServiceCard";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { motion, AnimatePresence } from "framer-motion";

import "swiper/css";

function App() {
  const [serviceCat, setServiceCat] = useState("all");

  const images = [
    "/images/main-img.png",
    "/images/main-img2.png",
    "/images/main-img3.png",
    "/images/main-img4.png",
    "/images/main-img5.jpg",
    "/images/main-img6.jpg",
    "/images/main-img.png",
    "/images/main-img2.png",
    "/images/main-img3.png",
    "/images/main-img4.png",
    "/images/main-img5.jpg",
    "/images/main-img6.jpg",
  ];

  const fadeUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const springTransition = {
    type: "spring",
    stiffness: 100,
    damping: 15,
    mass: 1,
  };

  return (
    <MainLayout>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={fadeUpVariants}
        transition={{ ...springTransition, delay: 0.2 }}
      >
        <HeroSection>
          <div className="text-and-buttons">
            <img id="dots-img" src="/images/dots.svg" />
            <LargeText>
              Reliable Precision Alignment is here to support your business{" "}
              <span>nationwide</span>!
            </LargeText>
            <SmallText>
              We offer premium maintenance support for conveyance and facilities
              assets. Partnering with your maintenance team, we ensure all
              equipment and building assets are in top condition.
            </SmallText>
            <div className="buttons">
              <Button $isOutlined="true">
                <Link className="nav-btn" to="/careers">
                  Careers
                </Link>
              </Button>
              <Button>
                <Link className="nav-btn" to="/contact">
                  Contact Us
                </Link>
              </Button>
            </div>
          </div>
          <div className="home-img">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
                stopOnLastSlide: false,
                waitForTransition: true,
              }}
              modules={[Navigation, Pagination, Autoplay]}
              loop={false}
              id="main-img"
            >
              {images.map((image, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={image}
                    id="main-img"
                    alt={`Main Image ${index + 1}`}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <img id="rings-img" src="/images/round.svg" />
          </div>

          <TrustedWrapper>
            <div className="trusted-by">
              <Line />
              <span>Trusted Nationwide By</span>
              <Line />
            </div>
            <div className="brand-logos">
              <img src="/images/Amazon_logo 1.svg" alt="amazon logo" />
              <img src="/images/chewy-2 1.svg" alt="chewy logo" />
              <img src="/images/medline-logo.svg" alt="medline logo" />
            </div>
          </TrustedWrapper>
        </HeroSection>
      </motion.div>

      <motion.div
        initial="hidden"
        animate="visible"
        variants={fadeUpVariants}
        transition={{ ...springTransition, delay: 0.4 }}
      >
        <OurServicesWrapper>
          <LargeText>Our Services</LargeText>
          <div className="service-filter-btns">
            <Button
              $isServices={true}
              $isSelected={serviceCat === "all"}
              onClick={() => {
                setServiceCat("all");
              }}
            >
              All
            </Button>
            <Button
              $isServices={true}
              $isSelected={serviceCat === "conveyance"}
              onClick={() => {
                setServiceCat("conveyance");
              }}
            >
              Conveyance
            </Button>
            <Button
              $isServices={true}
              $isSelected={serviceCat === "facilities"}
              onClick={() => {
                setServiceCat("facilities");
              }}
            >
              Facilities
            </Button>
            <Button
              $isServices={true}
              $isSelected={serviceCat === "TBR"}
              onClick={() => {
                setServiceCat("TBR");
              }}
            >
              TBR
            </Button>
            <Button
              $isServices={true}
              $isSelected={serviceCat === "crossovers"}
              onClick={() => {
                setServiceCat("crossovers");
              }}
            >
              Cross-overs
            </Button>
            <Button
              $isServices={true}
              $isSelected={serviceCat === "maxreach"}
              onClick={() => {
                setServiceCat("maxreach");
              }}
            >
              Max-reach{" "}
            </Button>
          </div>
          <ServiceGridWrapper>
            <AnimatePresence>
              {services.length > 0 &&
                services
                  .filter((service) =>
                    serviceCat === "all"
                      ? true
                      : service.category.toLowerCase() ===
                        serviceCat.toLowerCase()
                  )
                  .map((service, index) => (
                    <ServiceCard
                      key={service.id}
                      service={service}
                      index={index}
                    />
                  ))}
            </AnimatePresence>
          </ServiceGridWrapper>
        </OurServicesWrapper>
      </motion.div>

      <motion.div
        initial="hidden"
        animate="visible"
        variants={fadeUpVariants}
        transition={{ ...springTransition, delay: 0.6 }}
      >
        <ResourcesWrapper>
          <img src="/images/dots.svg" />
          <span className="title">
            About Reliable Precision Alignment (RPA)
          </span>
          <span className="description">
            Reliable Precision Alignment (RPA) offers premium repair services
            for conveyance systems and facilities. Discover our commitment to
            safety, reliability, and precision.
          </span>

          <button>
            <Link className="resource-link" to="/about">
              About us{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="17"
                viewBox="0 0 15 17"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.10625 2.81209C8.19414 2.7126 8.31328 2.65672 8.4375 2.65672C8.56172 2.65672 8.68086 2.7126 8.76875 2.81209L13.4563 8.12459C13.544 8.2242 13.5933 8.35922 13.5933 8.50001C13.5933 8.64079 13.544 8.77581 13.4563 8.87542L8.76875 14.1879C8.72584 14.2401 8.67409 14.282 8.61659 14.311C8.55909 14.3401 8.49702 14.3557 8.43408 14.3569C8.37114 14.3582 8.30862 14.3451 8.25025 14.3183C8.19188 14.2916 8.13886 14.2519 8.09435 14.2014C8.04984 14.151 8.01475 14.0909 7.99117 14.0247C7.9676 13.9586 7.95602 13.8877 7.95713 13.8164C7.95824 13.7451 7.97202 13.6747 7.99764 13.6095C8.02326 13.5444 8.0602 13.4857 8.10625 13.4371L11.9938 9.03126H1.875C1.75068 9.03126 1.63145 8.97528 1.54354 8.87566C1.45564 8.77603 1.40625 8.6409 1.40625 8.50001C1.40625 8.35911 1.45564 8.22398 1.54354 8.12436C1.63145 8.02473 1.75068 7.96876 1.875 7.96876H11.9938L8.10625 3.56292C8.01847 3.46331 7.96916 3.32829 7.96916 3.18751C7.96916 3.04672 8.01847 2.9117 8.10625 2.81209Z"
                  fill="#143252"
                />
              </svg>
            </Link>
          </button>
        </ResourcesWrapper>
      </motion.div>

      <motion.div
        initial="hidden"
        animate="visible"
        variants={fadeUpVariants}
        transition={{ ...springTransition, delay: 0.8 }}
      >
        <ResourcesWrapper $isContact={true}>
          <span className="title">Get in Touch with Us</span>
          <span className="description">
            We are available to assist you with your maintenance needs anytime
            and anywhere. That includes after hours, weekends, and holidays.
          </span>

          <button>
            <Link className="resource-link" to="/contact">
              Contact us{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="17"
                viewBox="0 0 15 17"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.10625 2.81209C8.19414 2.7126 8.31328 2.65672 8.4375 2.65672C8.56172 2.65672 8.68086 2.7126 8.76875 2.81209L13.4563 8.12459C13.544 8.2242 13.5933 8.35922 13.5933 8.50001C13.5933 8.64079 13.544 8.77581 13.4563 8.87542L8.76875 14.1879C8.72584 14.2401 8.67409 14.282 8.61659 14.311C8.55909 14.3401 8.49702 14.3557 8.43408 14.3569C8.37114 14.3582 8.30862 14.3451 8.25025 14.3183C8.19188 14.2916 8.13886 14.2519 8.09435 14.2014C8.04984 14.151 8.01475 14.0909 7.99117 14.0247C7.9676 13.9586 7.95602 13.8877 7.95713 13.8164C7.95824 13.7451 7.97202 13.6747 7.99764 13.6095C8.02326 13.5444 8.0602 13.4857 8.10625 13.4371L11.9938 9.03126H1.875C1.75068 9.03126 1.63145 8.97528 1.54354 8.87566C1.45564 8.77603 1.40625 8.6409 1.40625 8.50001C1.40625 8.35911 1.45564 8.22398 1.54354 8.12436C1.63145 8.02473 1.75068 7.96876 1.875 7.96876H11.9938L8.10625 3.56292C8.01847 3.46331 7.96916 3.32829 7.96916 3.18751C7.96916 3.04672 8.01847 2.9117 8.10625 2.81209Z"
                  fill="#143252"
                />
              </svg>
            </Link>
          </button>
        </ResourcesWrapper>
      </motion.div>
    </MainLayout>
  );
}

export default App;
