import styled, { css } from "styled-components";

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 624px;
`;

export const TextBlock = styled.div`
  max-width: 624px;
  text-align: start;
`;
