import { MainLayout } from "../../index.style";
import {
  ContactInfo,
  ContactWrapper,
  FormWrapper,
} from "../../styles/pages/contact.style";

import { Button } from "../../styles/global/main.style";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
const Careers = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  emailjs.init("hnmTkJcIe7zeb7MMi");

  const onSubmit = (data) => {
    emailjs.send("service_zou6btr", "template_qk92avz", data).then(
      (response) => {
        navigate("/success");
        reset(); // Reset form after successful submission
      },
      (error) => {
        console.log("FAILED...", error);
        alert("Failed to send message. Please try again.");
      }
    );
  };
  return (
    <MainLayout>
      <ContactWrapper>
        <div className="text">
          <div className="contact-header">
            <span id="header">Careers</span>
            <span id="sub-header">
              Have questions or need our services? Reach out to us using the
              form below, and we'll get back to you soon.
            </span>
          </div>
          <div className="contact-info">
            <ContactInfo>
              <img src="/images/mail-ico.svg" alt="Email" />
              service@rpa-md.com
            </ContactInfo>
            <ContactInfo>
              <img src="/images/phone-ico.svg" alt="Phone" />
              540-748-3340
            </ContactInfo>
          </div>
          <div className="hours-info">
            <span className="form-header">Hours</span>
            <span id="sub-header">
              We are available to assist you with your maintenance needs anytime
              and anywhere. That includes after hours, weekends, and holidays.
            </span>
          </div>
        </div>
        <FormWrapper>
          <span className="form-header">Get in Touch</span>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="name">
              <label htmlFor="firstName">
                First name
                <input
                  id="firstName"
                  className={`input ${errors.firstName ? "error" : ""}`}
                  placeholder="Jane"
                  {...register("firstName", {
                    required: "First name is required",
                  })}
                />
                {errors.firstName && (
                  <span className="error-message">
                    {errors.firstName.message}
                  </span>
                )}
              </label>
              <label htmlFor="lastName">
                Last name
                <input
                  id="lastName"
                  className={`input ${errors.lastName ? "error" : ""}`}
                  placeholder="Doe"
                  {...register("lastName", {
                    required: "Last name is required",
                  })}
                />
                {errors.lastName && (
                  <span className="error-message">
                    {errors.lastName.message}
                  </span>
                )}
              </label>
            </div>
            <label htmlFor="email">
              Email address
              <input
                id="email"
                type="email"
                className={`input ${errors.email ? "error" : ""}`}
                placeholder="jane.doe@example.com"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email && (
                <span className="error-message">{errors.email.message}</span>
              )}
            </label>
            <label htmlFor="phoneNumber">
              Phone Number
              <input
                id="phoneNumber"
                type="tel"
                className={`input ${errors.phoneNumber ? "error" : ""}`}
                placeholder="+1 (123)-456-7891"
                {...register("phoneNumber", {
                  required: "Phone number is required",
                })}
              />
              {errors.phoneNumber && (
                <span className="error-message">
                  {errors.phoneNumber.message}
                </span>
              )}
            </label>
            <label htmlFor="message">
              Your message
              <textarea
                id="message"
                className={`input ${errors.message ? "error" : ""}`}
                placeholder="Type your message here..."
                {...register("message", { required: "Message is required" })}
              />
              {errors.message && (
                <span className="error-message">{errors.message.message}</span>
              )}
            </label>
            <Button type="submit">Submit</Button>
          </form>
        </FormWrapper>
      </ContactWrapper>
    </MainLayout>
  );
};

export default Careers;
