import styled from "styled-components";


export const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95vw;
  max-width: 1300px;
  height: 100%;
  text-align: center;

  .contact-methods {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
  }

  #hours-desc {
    max-width: 360px;
    text-align: center;
  }

  .contact-method {
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 8px;
  }

  .hours-wrapper {
    margin-top: 10px;
  }
`;
