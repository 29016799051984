import { Link } from "react-router-dom";
import { ServiceCardWrapper } from "../../styles/components/service.style";
import { motion } from "framer-motion";

const ServiceCard = ({ service, index }) => {
  const IconComponent = service.icon;

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        type: "spring",
        stiffness: 100,
        damping: 12,
        delay: index * 0.1,
      }}
    >
      <ServiceCardWrapper>
        <div className="icon">
          <IconComponent size={24} color="white" />
        </div>
        <span className="service-name">{service.title}</span>
        <span className="service-description">{service.description}</span>
        <div className="more-details">
          <Link className="text" to="/contact">
            more details
          </Link>
          <i>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M5.5 3L10.5 8L5.5 13"
                stroke="#626262"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </i>
        </div>
      </ServiceCardWrapper>
    </motion.div>
  );
};

export default ServiceCard;
