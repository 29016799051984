import styled, { css } from "styled-components";

import theme from "../global/theme";
import { mediaQueries } from "../../utils/mediaQuery";

export const FooterContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  height: 150px;
  border-top: 1px solid ${theme.color.lightgrey};
  margin-top: 50px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 1300px;
  align-items: center;

  ${mediaQueries("md")`
    flex-direction: column;
`}
`;

export const FooterNavWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 50px;

  .nav {
    color: var(--Black, #1f1e1e);
    text-align: center;
    font-family: Mundial;
    font-size: ${theme.fontSize.md};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
    text-decoration: none;

    ${mediaQueries("md")`
    font-size: ${theme.fontSize.sm};
`}
  }

  .logo {
    width: 77px;
    height: 70px;
  }

  .nav-links {
    display: flex;

    gap: 10px;
  }

  ${mediaQueries("md")`
    flex-direction: column;
    gap: 2.5rem;
    margin: 10rem 0 2.5rem 0;
`}
`;

export const SocialWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  img {
    width: 30px;
    auto;
  }
`;

export const CopyRightWrapper = styled.div`
  color: var(--grey, #626262);
  text-align: center;
  font-family: Mundial;
  font-size: ${theme.fontSize.xs};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.5px;
  margin-top: 5px;
`;
