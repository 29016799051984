import { MainLayout } from "../../index.style";
import { LargeText, SmallText } from "../../styles/global/main.style";
import { SuccessWrapper } from "../../styles/pages/success.style";

const SuccessPage = () => {
  return (
    <MainLayout>
      <SuccessWrapper>
        <img id="check-icon" src="/images/successCheck.svg" />
        <LargeText>Message Sent!</LargeText>
        <div className="contact-methods">
          <SmallText>
            Thank you for reaching out! We will be in touch shortly to address
            your needs.
          </SmallText>
          <SmallText className="contact-method">
            <img src="../images/mail-ico.svg" alt="mail img" />
            service@rpa-md.com
          </SmallText>
          <SmallText className="contact-method">
            <img src="../images/phone-ico.svg" alt="mail img" />
            540-748-3340
          </SmallText>
        </div>
        <div className="hours-wrapper">
          <LargeText>Hours</LargeText>
          <SmallText id="hours-desc">
            We are available to assist you with your maintenance needs anytime
            and anywhere. That includes after hours, weekends, and holidays. 
          </SmallText>
        </div>
      </SuccessWrapper>
    </MainLayout>
  );
};

export default SuccessPage;
