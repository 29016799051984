import { Link } from "react-router-dom";
import {
  FooterContainer,
  FooterWrapper,
  SocialWrapper,
  FooterNavWrapper,
  CopyRightWrapper,
} from "../../styles/components/footer.style";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterNavWrapper>
          <div className="logo">
            <Link to="/">
              <img src="/images/logo.png" alt="rpa logo" />
            </Link>
          </div>
          <div className="nav-links">
            <Link className="nav" to="/">
              Home
            </Link>
            <Link className="nav" to="/about">
              About us
            </Link>
            <Link className="nav" to="/">
              Contact us
            </Link>
            <Link className="nav" to="/about">
              Careers
            </Link>
          </div>
        </FooterNavWrapper>
        <SocialWrapper>
          <a href="https://www.facebook.com/people/Reliable-Precision-Alignment/100094049109968/">
            <img src="/images/fb.svg" alt="facebook logo" />
          </a>
          <a href="https://www.linkedin.com/company/rpa-md">
            <img src="/images/linkedin.svg" alt="linkedin logo" />
          </a>
          <a href="https://www.youtube.com/channel/UCy8sPAUrZ27aXPnQIvHxgpg">
            <img src="/images/youtube.svg" alt="youtube logo" />
          </a>
          <a href="https://www.yelp.com/biz/reliable-precision-alignment-black-forest">
            <img src="/images/yelp.svg" alt="yelp logo" />
          </a>
        </SocialWrapper>
      </FooterWrapper>
      <CopyRightWrapper>
        © Copyright 2024, RPA. All Rights Reserved.
      </CopyRightWrapper>
    </FooterContainer>
  );
};

export default Footer;
