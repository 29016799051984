import styled, { css } from "styled-components";
import { mediaQueries } from "../../utils/mediaQuery";
import { motion } from "framer-motion";
import theme from "../global/theme";

// Main container for the header
export const HeaderContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  margin-bottom: 5rem;
  z-index: 5;
`;

// Wrapper for header content
export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 1300px;
  z-index: 5;

  ${mediaQueries("md")`
    .logo-lg {
      display: none;
    }
  `}
`;

// Navigation wrapper
export const NavWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 80px;

  // Styling for navigation links
  .nav {
    color: var(--Black, #1f1e1e);
    text-align: center;
    font-family: Mundial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
    text-decoration: none;
  }

  // Container for buttons
  .btns {
    display: flex;
    gap: 20px;
  }

  // Container for links
  .links {
    display: flex;
    gap: 20px;
  }

  // Logo styling
  .logo {
    width: 77px;
    height: 70px;
  }

  // Hide on medium screens and below
  ${mediaQueries("md")`
    display: none;
  `}
`;

// Mobile menu button
export const MobileMenuButton = styled.div`
  display: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;

  // Show on medium screens and below
  ${mediaQueries("md")`
    display: block;
  `}
`;

// Mobile menu component using Framer Motion
export const MobileMenu = styled(motion.nav)`
  display: none;

  // Show on medium screens and below
  ${mediaQueries("md")`
    display: flex;
    flex-direction: column;
    background: white;
    position: absolute;
    top: 80px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: -1;
    justify-content: start;
    padding: 5rem 0;

    a {
      padding: 10px 5rem;
      color: var(--Text-Midnight-Black, #282828);
font-family: Mundial;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 30px */
letter-spacing: 0.2px;
text-decoration: none;
    }
  `}
`;
