export default {
  color: {
    primary: "#143252",
    black: "#1F1E1E",
    grey: "#626262",
    lightgrey: "#BDBDBD",
    bordercolor: "#E9E9E9",
  },
  font: {
    fontFamilyMundial: "Mundial",
  },
  fontSize: {
    xs: "14px",
    sm: "16px",
    md: "20px",
    lg: "26px",
    xl: "32px",
    xxl: "40px",
  },
};
