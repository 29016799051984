import {
  Settings,
  Tool,
  Zap,
  Gift,
  Maximize,
  Minimize,
  Archive,
  CheckSquare,
  Key,
  Home,
  Wind,
  Edit,
  Link,
  Layers,
  Truck,
  Briefcase,
  Shield,
  Power,
  Droplet,
  Feather,
  Thermometer,
  Anchor,
  GitBranch,
  Clock,
  Calendar,
  AlertTriangle,
  ZoomIn,
  Crosshair,
  Crop,
  Edit3,
  Square,
  Scissors,
  BatteryCharging,
  Server,
} from "react-feather";

const services = [
  {
    id: 1,
    icon: Crosshair,
    title: "Precision alignment",
    description:
      "Expert alignment of motors, rollers, and conveyor belts for optimal performance and efficiency.",
    category: "Conveyance",
  },
  {
    id: 2,
    icon: Tool,
    title: "Heavy roller replacement",
    description:
      "Skilled replacement of heavy rollers to ensure smooth operation of conveyor systems.",
    category: "Conveyance",
  },
  {
    id: 3,
    icon: Zap,
    title: "Motor changes",
    description:
      "Swift and efficient motor replacements to minimize downtime and maximize productivity.",
    category: "Conveyance",
  },
  {
    id: 4,
    icon: Settings,
    title: "Gear box maintenance",
    description:
      "Comprehensive gear box servicing to ensure long-lasting reliability and performance.",
    category: "Conveyance",
  },
  {
    id: 5,
    icon: Maximize,
    title: "Conveyance expansions",
    description:
      "Custom design and implementation of conveyor system expansions to meet growing operational needs.",
    category: "Conveyance",
  },
  {
    id: 6,
    icon: ZoomIn,
    title: "Telescopic conveyance repair",
    description:
      "Specialized repair and maintenance of telescopic conveyance systems for optimal functionality.",
    category: "Conveyance",
  },
  {
    id: 7,
    icon: Crop,
    title: "Manual conveyance assembly",
    description:
      "Skilled assembly of manual conveyance systems tailored to specific operational requirements.",
    category: "Conveyance",
  },
  {
    id: 8,
    icon: CheckSquare,
    title: "Conveyance inspections",
    description:
      "Thorough inspections and audits of conveyor systems to ensure safety and efficiency.",
    category: "Conveyance",
  },
  {
    id: 9,
    icon: Key,
    title: "Handicap door services",
    description:
      "Installation, replacement, and adjustment of handicap door operators for improved accessibility.",
    category: "Facilities",
  },
  {
    id: 10,
    icon: Home,
    title: "Door replacement",
    description:
      "Expert replacement of interior and exterior doors for enhanced security and aesthetics.",
    category: "Facilities",
  },
  {
    id: 11,
    icon: Wind,
    title: "Wind breaker installation",
    description:
      "Installation of wind breakers to improve comfort and energy efficiency in facilities.",
    category: "Facilities",
  },
  {
    id: 12,
    icon: Edit3,
    title: "Painting and repairs",
    description:
      "Professional painting services and wall repairs to maintain and improve facility appearance.",
    category: "Facilities",
  },
  {
    id: 13,
    icon: Square,
    title: "Fence repairs",
    description:
      "Repair and maintenance of interior and exterior fences for security and aesthetics.",
    category: "Facilities",
  },
  {
    id: 14,
    icon: Layers,
    title: "Retaining wall services",
    description:
      "Construction and repair of retaining walls to manage soil erosion and improve landscaping.",
    category: "Facilities",
  },
  {
    id: 15,
    icon: Truck,
    title: "Truck yard improvements",
    description:
      "Comprehensive repairs and upgrades to truck yards, including bumper installation and general improvements.",
    category: "Facilities",
  },
  {
    id: 16,
    icon: Briefcase,
    title: "Office furniture services",
    description:
      "Assembly and relocation of office furniture to optimize workspace efficiency.",
    category: "Facilities",
  },
  {
    id: 17,
    icon: Shield,
    title: "Guard shack repairs",
    description:
      "Maintenance and repair of guard shacks to ensure security and functionality.",
    category: "Facilities",
  },
  {
    id: 18,
    icon: Zap,
    title: "Welding services",
    description:
      "Professional welding services for various facility repair and construction needs.",
    category: "Facilities",
  },
  {
    id: 19,
    icon: Droplet,
    title: "Traptex® installation",
    description: "Installation of Traptex® Plumbing Protection System.",
    category: "Facilities",
  },
  {
    id: 20,
    icon: BatteryCharging,
    title: "Electrical services",
    description:
      "Installation and relocation of electrical drops to meet changing facility needs.",
    category: "Facilities",
  },
  {
    id: 21,
    icon: Scissors,
    title: "Landscaping services",
    description:
      "Professional landscaping design and improvements to enhance facility appearance.",
    category: "Facilities",
  },
  {
    id: 22,
    icon: Server,
    title: "HVAC maintenance",
    description:
      "General repairs and maintenance of HVAC systems to ensure optimal climate control.",
    category: "Facilities",
  },
  {
    id: 23,
    icon: Anchor,
    title: "Truck Block Restraints (TBRs)",
    description:
      "Custom-sized TBRs bolted to the ground to secure parking blocks, preventing damage from accidental truck impacts.",
    category: "TBR",
  },
  {
    id: 24,
    icon: Clock,
    title: "Cross-over",
    description:
      "Assembly and installation of any sized cross-over as well as disassembly and decommissioning.",
    category: "crossovers",
  },
  {
    id: 25,
    icon: Calendar,
    title: "Quarterly PM",
    description: "",
    category: "maxreach",
  },
  {
    id: 26,
    icon: AlertTriangle,
    title: "Annual Maintenance",
    description: "",
    category: "maxreach",
  },
  {
    id: 27,
    icon: AlertTriangle,
    title: "Reactive/SEV Maintenance",
    description: "",
    category: "maxreach",
  },
];

export default services;
