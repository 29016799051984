import { MainLayout } from "../../index.style";
import { LargeText, SmallText } from "../../styles/global/main.style";
import { TextBlock, TextWrapper } from "../../styles/pages/about-us.style";

const AboutUs = () => {
  return (
    <MainLayout>
      <div className="about-us-layout">
        <TextWrapper>
          <LargeText isAboutUs={true}>About Us</LargeText>
          <TextBlock>
            <SmallText isAboutUs={true}>
              Reliable Precision Alignment (RPA) is committed to providing
              premium repair services to conveyance systems and facilities
              assets. We have developed industry leading techniques and
              equipment that provides your business with the most reliable
              repairs.
            </SmallText>
          </TextBlock>
          <LargeText isAboutUs={true}>Mission</LargeText>
          <TextBlock>
            <SmallText isAboutUs={true}>
              Our mission is to provide our full range of maintenance services
              in a safe, reliable, precise, and cost-effective manner. We make
              it our priority to ensure your business is our business.
            </SmallText>
          </TextBlock>
          <LargeText isAboutUs={true}>Vision</LargeText>
          <TextBlock>
            <SmallText isAboutUs={true}>
              We believe that maintenance is one of the most important tools for
              optimizing production and ensuring health and safety. Our vision
              is to be the preferred maintenance company, providing the most
              reliable repairs on a consistent basis, to drive meaningful value
              to our customers. We strive to be the best at what we do.
            </SmallText>
          </TextBlock>
        </TextWrapper>
        <img className="about-us-img" src="/images/about-us-img.png" />
      </div>
    </MainLayout>
  );
};

export default AboutUs;
