import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  HeaderContainer,
  HeaderWrapper,
  NavWrapper,
  MobileMenuButton,
  MobileMenu,
} from "../../styles/components/header.style";
import { Button } from "../../styles/global/main.style";
import { motion } from "framer-motion";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuVariants = {
    closed: {
      x: "100%",
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
    },
    open: {
      x: 0,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
    },
  };

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <Link to="/">
          <img src="/images/logo.png" alt="Logo" />
        </Link>

        <NavWrapper className="desktop-nav">
          <div className="links">
            <Link className="nav" to="/">
              Home
            </Link>
            <Link className="nav" to="/about">
              About us
            </Link>
          </div>
          <div className="btns">
            <Button $isOutlined="true">
              <Link className="nav-btn" to="/careers">
                Careers
              </Link>
            </Button>
            <Button>
              <Link className="nav-btn" to="/contact">
                Contact Us
              </Link>
            </Button>
          </div>
        </NavWrapper>

        <MobileMenuButton onClick={toggleMenu}>
          {isMenuOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
            >
              <path
                d="M9 27L27 9M9 9L27 27"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
            >
              <path
                d="M5.625 13.5H30.375M5.625 23.625H30.375"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </MobileMenuButton>

        {isMenuOpen && (
          <MobileMenu
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
          >
            <Link className="nav" to="/" onClick={toggleMenu}>
              Home
            </Link>
            <Link className="nav" to="/about" onClick={toggleMenu}>
              About us
            </Link>
            <Link className="nav" to="/careers" onClick={toggleMenu}>
              Careers
            </Link>
            <Link className="nav" to="/contact" onClick={toggleMenu}>
              Contact Us
            </Link>
          </MobileMenu>
        )}
      </HeaderWrapper>
    </HeaderContainer>
  );
};

export default Header;
