import styled, { css } from "styled-components";

import theme from "../global/theme";
import { mediaQueries } from "../../utils/mediaQuery";

export const ContactWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1300px;

  justify-content: space-between;

  flex-wrap: wrap;
  gap: 60px;

  .text {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    justify-content: start;
    align-items: start;
    gap: 25px;

    #header {
      color: #000;
      font-family: Mundial;
      font-size: 64px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -1.28px;

      ${mediaQueries("md")`
    font-size: 50px;
`}
    }

    #sub-header {
      color: #828282;
      font-family: Mundial;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 124%; /* 24.8px */

      ${mediaQueries("md")`
        font-size: 16px;
    `}
    }

    .form-header {
      color: var(--Black, #1f1e1e);
      font-family: Mundial;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.64px;
    }

    .contact-header {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .contact-info {
      display: flex;
      align-items: start;
      flex-direction: column;
      gap: 10px;
    }

    .hours-info {
      display: flex;
      flex-direction: column;
      gap: 22px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 730px;
    padding: 25px;
    border-radius: 2px;
    border: 1px solid var(--border-color, #e2e2e2);
    background: #fff;
    gap: 10px;
  }
`;

export const FormWrapper = styled.div`
  max-width: 55rem;
  padding: 4rem;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  border: 1px solid var(--border-color, #e2e2e2);
  background: #fff;
  position: relative;
  flex: 1 1 300px;
  .input {
    width: 100%;

    height: 48px;
    border-radius: 2px;
    border: 1px solid #e0e0e0;
    background: #fff;
    padding: 0;
    font-size: 18px;
    font-family: ${theme.font.fontFamilyMundial};
    color: ${theme.color.black};
    font-weight: 400;
    padding: 10px;

    /* Button Shadow */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

    &:focus {
      outline: 2px solid ${theme.color.primary};
    }

    &::placeholder {
      color: ${theme.color.lightgrey};
    }
  }

  label {
    color: #000;
    font-family: ${theme.font.fontFamilyMundial};

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }

  .name {
    display: flex;
    gap: 32px;
  }

  .form-header {
    color: var(--Black, #1f1e1e);
    font-family: Mundial;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.64px;
    margin-bottom: 3rem;

    ${mediaQueries("md")`
      font-size: 25px;
  `}
  }

  form {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }

  #message {
    height: 16rem;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: -49px;
    right: -46px;
    width: 124px;
    height: 107.064px;
    transform: rotate(2deg);
    background-image: url("/images/round.svg");
    background-size: contain;
    background-repeat: no-repeat;

    ${mediaQueries("md")`
    display: none;
`}
  }

  &:after {
    content: "";
    position: absolute;
    top: -49px;
    left: -46px;
    width: 124px;
    height: 107.064px;
    transform: rotate(2deg);
    background-image: url("/images/contact-dots.svg");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;

    ${mediaQueries("md")`
      display: none;
  `}
  }

  ${mediaQueries("md")`
      padding: 2.5rem;
  `}

  .error-message {
    color: red;
  }
`;

export const ContactInfo = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  color: #828282;
  font-family: Mundial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 24.8px */

  ${mediaQueries("md")`
    font-size: 16px;
`}
`;
