export const breakpoints = {
  xs: 480,
  sm: 576,
  md: 820,
  lg: 1000,
  xl: 1227,
};

export const mediaQueries = (key) => (style) =>
  `@media (max-width: ${breakpoints[key]}px) { ${style} }`;
